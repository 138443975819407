<template>
  <div class="home">
    <el-container>
      <el-aside width="200px" class="asidemenu">
        <asideblock />
      </el-aside>
      <el-main>
        <headtable class="headmenu" />
        <el-card class="topbar">
          <p>群 {{ groupid }} 的管理员，</p>
          <p>欢迎使用 Pichubot 大学习管理功能</p>
        </el-card>
        <el-card class="box-card" style="min-height: 80%">
        <h2>青年大学习完成情况</h2>
        <el-divider></el-divider>
        <div class="progress">
          <el-progress :text-inside="true" :stroke-width="24" :percentage="Math.round(comp/max*100)" status="success"></el-progress>
          <span>完成人数：{{ comp }} / {{ max }}</span>
        </div>
        <div class="uncomplist">
          <h2>未完成人员名单</h2>
          <el-table
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)"
            style="width: 100%">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              prop="name"
              label="名称">
            </el-table-column>
            <el-table-column
              prop="qq"
              label="QQ">
            </el-table-column>
          </el-table>

          <el-pagination
            style="margin-top:10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.length">
          </el-pagination>
        </div>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src

import asideblock from '@/components/asideblock.vue'
import headtable from '@/components/headtable.vue'

export default {
  name: 'Home',
  components: {
    asideblock,
    headtable
  },
  data() {
    return {
      groupid: 1234567890,
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      comp: 0,
      max: 1
    }
  },
   methods: {
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.currentPage = 1;
        this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val;
    },
    getCompData() {
      this.$http({
        url: 'getcomp',
        method: 'get',
        params: {
        groupid: this.groupid,
        value: this.$cookies.get("userId"),
      }})
      .then((response) => {
        if (response.status == 200 && response.data.code == 200) {
          this.tableData = response.data.data.uncomplist;
          this.comp = response.data.data.comp;
          this.max = response.data.data.uncomp + this.comp;
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  },
  created() {
    this.groupid = this.$cookies.get('groupid');
    this.getCompData();
  }
}
</script>

<style>
.topbar {
  margin-bottom: 10px;
  line-height: 5px;
  font-size: 1em;
  border-radius: 30px
}
.progress {
  text-align: center;
  font-size: 0.9em;
}

.progress span {
  color: rgb(143, 143, 143);
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .asidemenu {
    display: none;
  }
  .headmenu {
    display: visible;
  }
}

@media screen and (min-width: 768px) {
  .asidemenu {
    display: visible;
  }
  .headmenu {
    display: none;
  }
}

.topbar p {
  display: inline-block;
  /*文字上下间距*/
  margin: 0.5em 0;
}
</style>
