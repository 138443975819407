<template>
  <div style="background-color:#464646;width:100%;min-height:100%;height:100vh" class="asideblock">
    <div class="logo">
      <p style="font-size:1.5em">Pichubot</p><p><strong>青年大学习</strong></p>
    </div>
    <el-menu
      :default-active="this.$route.path"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      class="asidemenu"
      router
    >
      <el-menu-item index="/index"><span>完成情况</span></el-menu-item>
      <el-menu-item index="/member"><span>成员编辑</span></el-menu-item>
      <el-menu-item index="/picture"><span>截图查看</span></el-menu-item>
      <div style="margin-top:200px"></div>
      <el-menu-item @click="jumptodoc()"><span>帮助文档</span></el-menu-item>
      <el-menu-item @click="logout()"><span>退出系统</span></el-menu-item>
    </el-menu>
  </div>
</template>

<script>
  export default {
    methods: {
      jumptodoc() {
        window.open('https://bot.im0o.top')
      },
      logout() {
        this.$cookies.remove('token');
        this.$cookies.remove('userId');
        this.$cookies.remove('userName');
        this.$cookies.remove('groupid');
        this.$router.push({path: '/login'});
      }
    }
  }
</script>

<style>
.logo {
  padding: 5px;
  color: rgb(255, 255, 255);
  line-height: 5px;
  text-align: center;
  font-family: Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
}
.asidemenu {
  text-align: center;
}

.asidemenu span {
  font-size:1.1em;
  margin-left: -5px;
  font-weight: bold;
}
</style>