<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  watch: {
    $route(to, from) {
      this.path = to.path
      if (
        !this.$cookies.get('token') || 
        !this.$cookies.get('userId') || 
        !this.$cookies.get('userName') ||
        !this.$cookies.get('groupid')
        ) {
        this.$cookies.remove('token');
        this.$cookies.remove('userId');
        this.$cookies.remove('userName');
        this.$cookies.remove('groupid');
        this.$router.push({path: '/login'});
      }
    }
  }
}
</script>

<style>
::-webkit-scrollbar{width:8px;height:4px}
::-webkit-scrollbar-button{width:8px;height:0}
::-webkit-scrollbar-track{background:0 0}
::-webkit-scrollbar-thumb{background:#c7c7c7;-webkit-transition:.3s;transition:.3s}
::-webkit-scrollbar-thumb:hover{background-color:#37dace}
::-webkit-scrollbar-thumb:active{background-color:#17bbaf}

#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0 auto;
}

</style>
