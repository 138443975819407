<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="完成情况" name="/index"></el-tab-pane>
    <el-tab-pane label="成员编辑" name="/member"></el-tab-pane>
    <el-tab-pane label="截图查看" name="/picture"></el-tab-pane>
    <el-tab-pane label="退出系统" name="logout"></el-tab-pane>
  </el-tabs>
</template>

<script>
  export default {
    data() {
      return {
        activeName: this.$router.currentRoute.path,
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
        if (tab.name == "logout") {
            this.$cookies.remove('token');
            this.$cookies.remove('userId');
            this.$cookies.remove('userName');
            this.$cookies.remove('groupid');
            this.$router.push("/login");
          }
        this.$router.push(tab.name);
      },
    }
  };
</script>