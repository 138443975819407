import axios from 'axios'
import router from '@/router'  //引入router
import cookies from 'vue-cookies'
import { Notification } from 'element-ui';

let instance = axios.create(); //创建Loading 的实例
instance.defaults.baseURL = "https://dxx-api.im0o.top:3333/api/"; // 配置axios请求的地址
// instance.defaults.baseURL = "http://192.168.50.243:7001/api/"; // 配置axios请求的地址
// instance.defaults.baseURL = "http://localhost:7001/api/"; // 配置axios请求的地址
// axios.defaults.baseURL = "/api/"; // 配置axios请求的地址
instance.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;  //设置cross跨域 并设置访问权限 允许跨域携带cookie信息
instance.defaults.headers.common['Authorization'] = ''; // 设置请求头为 Authorization
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // 设置请求头为 Authorization

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 每次发送请求之前判断vuex中是否存在token
    const token = cookies.get('token');
    token && (config.headers.Authorization = 'Bearer '+ token);
    return config;
  },
  error => {
    return Promise.error(error); // 抛出错误
  }
);

instance.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    
    // 如果返回的状态码和body中的code不一致，说明接口请求失败，抛出错误
    if (response.status === 200 && response.data.code !== 200) {
      switch (response.data.code) {
        case 1001:
          cookies.remove('token');
          cookies.remove('userId');
          cookies.remove('userName');
          router.push({path: '/login'});
          Notification({
            title: '登录超时',
            message: '请尝试重新登录',
            type: 'warning'
          });
          break;
        default:
          Notification({
            title: '提示',
            message: response.data.message,
            type: 'error'
          });
      }
      return Promise.reject(response);
    } else if (response.status === 200 && response.data.code === 200) {
      return Promise.resolve(response);
    }
  },
  error => {
    // 如果请求失败，返回错误
    return Promise.reject(error);
  }
);

export default instance