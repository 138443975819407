import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: '/index',
    name: 'Home',
    component: Home,
    meta: { title: "完成情况 | 青年大学习管理面板", hidden: true }
  },
  {
    path: '/member',
    name: 'Member',
    component: () => import(/* webpackChunkName: "about" */ '../views/Member.vue'),
    meta: { title: "成员编辑 | 青年大学习管理面板", hidden: true }
  },
  {
    path: '/picture',
    name: 'Picture',
    component: () => import(/* webpackChunkName: "about" */ '../views/Picture.vue'),
    meta: { title: "截图查看 | 青年大学习管理面板", hidden: true }
  },
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login"),
    meta: { title: "登录 | 青年大学习管理面板", hidden: true }
  },
]

const router = new VueRouter({
  routes
})

export default router
